export const TokenExchangeMap = {
    // Your complete tokenExchangeMap here...

        "NEAR": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x85F17Cf997934a597031b2E18a9aB6ebD4B9f6a4", 
            platform: "Ethereum" 
        }, 
        "RNDR": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x6De037ef9aD2725EB40118Bb1702EBb27e4Aeb24", 
            platform: "Ethereum" 
        }, 
        "INJ": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xe28b3B32B6c345A34Ff64674606124Dd5Aceca30", 
            platform: "Ethereum" 
        }, 
        "GRT": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xc944E90C64B2c07662A292be6244BDf05Cda44a7", 
            platform: "Ethereum" 
        }, 
        "FET": { 
            url: "https://www.binance.com/en/trade/FET_USDT", 
            platform: "Binance Smart Chain" 
        }, 
        "AGIX": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x5B7533812759B45C2B44C19e320ba2cD2681b542", 
            platform: "Ethereum" 
        }, 
        "ROSE": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x26B80FBfC01b71495f477d5237071242e0d959d7", 
            platform: "Ethereum" 
        }, 
        "THETA": { 
            url: "https://www.binance.com/en/trade/THETA_USDT", 
            platform: "Theta Network" 
        }, 
        "TFUEL": { 
            url: "https://www.binance.com/en/trade/TFUEL_USDT", 
            platform: "Theta Network" 
        }, 
        "AKT": { 
            url: "https://www.kucoin.com/trade/AKT-USDT", 
            platform: "Cosmos" 
        },
        "GLM": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x7DD9c5Cba05E151C895FDe1CF355C9A1D5DA6429", 
            platform: "Ethereum" 
        }, 
        "OCEAN": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x967da4048cD07aB37855c090aAF366e4ce1b9F48", 
            platform: "Ethereum" 
        },
        "TRAC": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xaA7AfbE1d4c43256d2e144f4FCf43fA7d15f6f42", 
            platform: "Ethereum" 
        },
        "CQT": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xD417144312DbF50465b1C641d016962017Ef6240", 
            platform: "Ethereum" 
        }, 
        "PHA": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x6C5BA91642F10282b576d91922AE6448C9d52f4E", 
            platform: "Ethereum" 
        }, 
        "NMR": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x1776e1F26f98b1A5dF9Cd347953a26dd3Cb46671", 
            platform: "Ethereum" 
        },
        "VIDT": { 
            url: "https://www.binance.com/en/trade/VIDT_USDT", 
            platform: "Binance Smart Chain" 
        },
        "HOOK": { 
            url: "https://www.binance.com/en/trade/HOOK_USDT", 
            platform: "Binance Smart Chain" 
        }, 
        "LMWR": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x12D6Ff8E79D89eD71c6B14F26DB23029C3d01A25", 
            platform: "Ethereum" 
        },
        "CTXC": { 
            url: "https://www.binance.com/en/trade/CTXC_USDT", 
            platform: "Binance Smart Chain" 
        },
        "RLC": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x607F4C5BB672230e8672085532f7e901544a7375", 
            platform: "Ethereum" 
        }, 
        "AI": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x8e3E24CcD5A21bA3cFfA85b2B1D14dE1A264eB4F", 
            platform: "Ethereum" 
        },
        "AIOZ": { 
            url: "https://www.binance.com/en/trade/AIOZ_USDT", 
            platform: "Binance Smart Chain" 
        },
        "AKITA": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x3F42Cd89FfF3C5B3Df3CCe4C6B2c1Acb6c6f3CB3", 
            platform: "Ethereum" 
        },
        "VRA": { 
            url: "https://www.binance.com/en/trade/VRA_USDT", 
            platform: "Binance Smart Chain" 
        },
        "SURE": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xCB21C1f0e3C891B34344Aa0A496eD1cFcE80309a", 
            platform: "Ethereum" 
        },
        "GNY": { 
            url: "https://www.bittrex.com/Market/Index?MarketName=BTC-GNY", 
            platform: "Ethereum" 
        },
        "MOOV": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x7ed7e0bA7429c306Ff06b47bD201eF54dA759F84", 
            platform: "Ethereum" 
        },
        "CGPT": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x1EdBC65b4Fe6afc9a14aaD95601FcE2E9E43D418", 
            platform: "Ethereum" 
        }, 
        "FLUX": { 
            url: "https://www.binance.com/en/trade/FLUX_USDT", 
            platform: "Binance Smart Chain" 
        },
        "CUDOS": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x817bbDbC3e8A1204f5A43E84F7B4cC5D8E56dCEc", 
            platform: "Ethereum" 
        },
        "GPU": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x4e3b17D1e5Eaf582EdC27B5C7e5Ff837c9d61F6b", 
            platform: "Ethereum" 
        },
        "TRIAS": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xE31a1677a7EDb13b12E1301B24aEf94716A5fD98", 
            platform: "Ethereum" 
        },
        "ALEPH": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x27702a26126e0B3702af63Ee09aC4d1A084EF628", 
            platform: "Ethereum" 
        },
        "CTXC": { 
            url: "https://www.binance.com/en/trade/CTXC_USDT", 
            platform: "Ethereum" 
        },
        "VR": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x9b68e3d4A6C5f849da5ACd0fbaF59E6d5EAFDEc8", 
            platform: "Ethereum" 
        },
        "DMTR": { 
            url: "https://www.binance.com/en/trade/DMTR_USDT", 
            platform: "Binance Smart Chain" 
        },
        "DATA": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x040aA56F04541bafE805C3bBcD7b1c8FfFecD5B5", 
            platform: "Ethereum" 
        },
        "APRS": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x57E2d25f085ed03C3e7bd1DeF71D6e40134e85C0", 
            platform: "Ethereum" 
        }, 
        "DIA": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x84cA78ae7A67DFD0B9e9b5E88F9FA1aA5fFf24f6", 
            platform: "Ethereum" 
        }, 
        "ALI": { 
            url: "https://www.binance.com/en/trade/ALI_USDT", 
            platform: "Binance Smart Chain" 
        }, 
        "AGRS": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x738865301A9b7Dd80Dc3679a6D1D0601fD6E9247", 
            platform: "Ethereum" 
        },
        "LAT": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x978fF50e9C67A6b0B3A3e0e760B1c1Cd5a2eC895", 
            platform: "Ethereum" 
        }, 
        "VAI": { 
            url: "https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=0x4BD17003473389A42DAF6a0a729f6Fdb328BbBd7", 
            platform: "Binance Smart Chain" 
        },
        "SIDUS": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x100C4C9Dab31F6611451b128630aC2E2C3C8824C", 
            platform: "Ethereum" 
        },
        "COMAI": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x5D653DA000eDF3Bb2d3a7BdB258e46f6e2d4C6B1", 
            platform: "Ethereum" 
        }, 
        "NUM": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xEcc95fC1B8fB81817b3b4F5dFF0C3e3B8404B7C4", 
            platform: "Ethereum" 
        }, 
        "BOTTO": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x92868A5255C628dA08F550a858A802F5351C5223", 
            platform: "Ethereum" 
        }, 
        "MDT": { 
            url: "https://www.binance.com/en/trade/MDT_USDT", 
            platform: "Binance Smart Chain" 
        },
        "SDAO": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x992E0f1b81ff3C6b8c91811e7c3f271d4263d2f4", 
            platform: "Ethereum" 
        },
        "VRA": { 
            url: "https://www.binance.com/en/trade/VRA_USDT", 
            platform: "Binance Smart Chain" 
        },
        "KEY": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x4C14559ec4A5bD6b5d2d2FA4aD00f21aA4247f86", 
            platform: "Ethereum" 
        },
        "VIRTUAL": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x754e7eF4c53A374c74B8FA8D6Fb04fE62d0bFcE6", 
            platform: "Ethereum" 
        }, 
        "PALM": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x74A774aFbB04b6D58eE0F5230Ae22cCe2c4F27E2", 
            platform: "Ethereum" 
        }, 
        "GLQ": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x3E3bC2A3D297E63Dd3606C6AF8E5f5b2E467D4f1", 
            platform: "Ethereum" 
        }, 
        "HAI": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x5bB6CfF5dECcb2AE25AfAbEc27c9B07324F3A990", 
            platform: "Ethereum" 
        }, 
        "CERE": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x1f5eD7eE89132DA4DBf02B3fEbd75fF03d68d24F", 
            platform: "Ethereum" 
        }, 
        "DEAI": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xC24d3fBf8A97D8aE51b3A1A99e32Fa27e53eA852", 
            platform: "Ethereum" 
        }, 
        "UPP": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x8aCF4D27Bb2316E278F52B367Dbf5d380B7ea98F", 
            platform: "Ethereum" 
        }, 
        "LIME": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x03fD5C9E2c91A1F735B5eC5b5948F72b4C9B2BcA", 
            platform: "Ethereum" 
        },
        "HGPT": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x1b01513B8D40bBfb3BaF7d9aF9D1919c769E95A3", 
            platform: "Ethereum" 
        },
        "PRQ": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x362C2FA6E69bD0AEcC291bbA7556D2CB9bF5A2d1", 
            platform: "Ethereum" 
        }, 
        "VXV": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x2e43b96A9d9814E432Bb73f4cD03ad24C1fB636F", 
            platform: "Ethereum" 
        }, 
        "NTX": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x5bE2C3a745AeF3f78bC25Cdbf0bBE1c3DC7EAb2B", 
            platform: "Ethereum" 
        }, 
        "VIDT": { 
            url: "https://www.binance.com/en/trade/VIDT_USDT", 
            platform: "Binance Smart Chain" 
        }, 
        "VOLT": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x556AF74Ce5C7A2A489E8D6BbB40bEDcfa56A6b9A", 
            platform: "Ethereum" 
        },
        "DOCK": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xeFe6d9b7a34A20a5b205E4fb7Bd72E88F1bD5a12", 
            platform: "Ethereum" 
        }, 
        "DBC": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x52347A8bB03437B6E65CE926DcaF3D2cA8e44AD4", 
            platform: "Ethereum" 
        },
        "OORT": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xF6F56Bfd5f35260B4dF526cEa88a0Fd90b45D59d", 
            platform: "Ethereum" 
        },
        "MAN": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x1530bAF2006F3cbd998d7C85C46C08bd4B8C769A", 
            platform: "Ethereum" 
        },
        "AIT": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xF56b4Fb9D7023eB754C5E8D2B7c08a2b7aB2d79C", 
            platform: "Ethereum" 
        },
        "DUEL": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xE1dD5bA1a13c15730fE59c178F5484329c9eAE3b", 
            platform: "Ethereum" 
        }, 
        "OPTI": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xA87D1DeF37cFBA092540DeBf7A4F6fB0fE2eB03a", 
            platform: "Ethereum" 
        }, 
        "GMRX": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x9e6fE6bb82b083D1eA2B1147D9A3F8701bD04eA1", 
            platform: "Ethereum" 
        }, 
        "LIKE": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xFE2e5E1eDe6eD72307eA6bb9e7fE365c569D105b", 
            platform: "Ethereum" 
        },
        "FITFI": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xAcA98cBf6eFf93eD2a91A2B08E44B9FFb4A9041E", 
            platform: "Ethereum" 
        },
        "BAD": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xBA63fCcF666359dE22Bf4DeB63C8FfD4Bc4E0555", 
            platform: "Ethereum" 
        },
        "OFN": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xa7B7f5D42BbFF1Da5AaEe299B1d1bD9c7f2434e2", 
            platform: "Ethereum" 
        }, 
        "DCK": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x96c6AEBc123Cec8B44c8A78bF369034528F8B3E7", 
            platform: "Ethereum" 
        },
        "SPECTRE": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x59aC298987edCB3A5Dd4b3b4B5e7D5CeB7c74a92", 
            platform: "Ethereum" 
        }, 
        "TRVL": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x755be3671Cc88cA740338Aaa594Ac7Ba7dD61388", 
            platform: "Ethereum" 
        }, 
        "NCDT": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x5cF88E6F5580C274c553b39a82dF3D35F46BBDd7", 
            platform: "Ethereum" 
        }, 
        "IMGNAI": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xCa2e5b720d9fFf0b75d11BbDDB8A5275Bb5bE9d1", 
            platform: "Ethereum" 
        },
        "BCUT": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xF350f4BFF29fDBE21A9F67e90Dc62efEB1b5D1c9", 
            platform: "Ethereum" 
        }, 
        "SNS": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x12D6E08A4b77b2f91B69b07F98867F4a0f0fC32e", 
            platform: "Ethereum" 
        }, 
        "SOUL": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x2F0B23BffDb8da0F8a3e4f6F5fEcF87a1c4eC1E2", 
            platform: "Ethereum" 
        }, 
        "NAVI": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x7a282Db3Ee91B7Ae8e07B9B0Bf6E8b2F2cF20367", 
            platform: "Ethereum" 
        },
        "OOKI": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x52156e8B77C12888AfB6cbBeB76e06D65f750f7D", 
            platform: "Ethereum" 
        }, 
        "TYPE": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xEd0b5F50dFfC6077c1c99bb1c1c44EFC7e31eD0a", 
            platform: "Ethereum" 
        }, 
        "BDP": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xA8B919680258d369114910511cc87595aec0be6D", 
            platform: "Ethereum" 
        }, 
        "LMR": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xDF5A0D7473A61201CE14CfD528EAF3024C5A489D", 
            platform: "Ethereum" 
        },
        "XRT": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x7de91B204c1C737bCda593D14aF3B4EdF6b8dB20", 
            platform: "Ethereum" 
        },
        "PIB": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x4C28f48448720e9000907BC2611F73022fdcE1fA", 
            platform: "Ethereum" 
        },
        "AIPAD": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xEca82185ADCE47f39c684352B0439f030f860318", 
            platform: "Ethereum" 
        },
        "SWASH": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x6B39600F96950Cb5F908eEEaC97cBccedE4Ed764", 
            platform: "Ethereum" 
        },
        "OMAX": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x0F1F5C0259483E9dEf6cD71cFc9C196dAdE22C5B", 
            platform: "Ethereum" 
        },
        "TADA": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x3Cd751E6b0078Be393132286c442345e5DC49699", 
            platform: "Ethereum" 
        },
        "LAMB": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x7e5A61Ed41cda3B9F69d49fBE198263c8b91f1C9", 
            platform: "Ethereum" 
        },
        "GTAI": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x20E4C4c43376b9Fc4B2D674fd5Db6a8d5FA5CB8F", 
            platform: "Ethereum" 
        },
        "EMC": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x0335F7B036B2D4b19f8a9f2B75fDA361A4415DD5", 
            platform: "Ethereum" 
        },
        "LITH": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x388071B848B1538C19F1c54B42a24C4e9b297E4b", 
            platform: "Ethereum" 
        },
        "LSS": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xE5caE71DcD84e9b5b4B860Ceb2E5cE80048bC042", 
            platform: "Ethereum" 
        }, 
        "SENATE": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x45804880De22913dAFE09f4980848ECE6EcbAf78", 
            platform: "Ethereum" 
        }, 
        "NETVR": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x3D4b0D5eBF1C1B5fcb42D6bA66a320c87a5e07b3", 
            platform: "Ethereum" 
        }, 
        "PRE": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x2cB44D5E432a64A4FdB4b57d7BE3fD19E08Fcf79", 
            platform: "Ethereum" 
        }, 
        "BRG": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xE5646D56375df4dF911b8C4f32dD8e0Ce92dA2B3", 
            platform: "Ethereum" 
        },
        "CGV": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x8f81fDc4Bb051C2827C50EBb154fFe056fFE998d", 
            platform: "Ethereum" 
        },
        "MARSH": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x9D3Ff3E181BDA3C59A1A851A9eABadA2A3DAeBdA", 
            platform: "Ethereum" 
        },
        "ISP": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x5a4B98DF197ef4a5f6A8e52a8bDEa30C8B6bED60", 
            platform: "Ethereum" 
        },
        "CHRP": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xA0aF07c65a32B7d2C6594AbA81f3E245BC7Ff5F8", 
            platform: "Ethereum" 
        },
        "MOZ": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x1736B36F97DAA1E9b8D2B5AEEe0F4B4E546cA0c4", 
            platform: "Ethereum" 
        },
        "PBR": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xD6e4607eD8fC0fF03fa0BFeAA92a47d9A59c8d92", 
            platform: "Ethereum" 
        }, 
        "DX": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x867D3D5d7B04BB2123a3F95Fe99bBAc0f1Cf3aD5", 
            platform: "Ethereum" 
        }, 
        "UFI": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x4d1E899E6f6aF20da236fEB010A265b4Ef6Df2A2", 
            platform: "Ethereum" 
        },
        "BCUBE": { 
            url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x8F60Fc1F73Ff60B1dD3F6A38E7A6FA8EC7B2f450", 
            platform: "Ethereum" 
        },
      "CTI": { 
                url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x8c18dA3cCE1E3267A6aC91c59D728a1205eC8dAa", 
                platform: "Ethereum" 
            },
            "EFX": { 
                url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xBef21e7Fb7A5dAf91A4bde78bD15d7D2799BAE84", 
                platform: "Ethereum" 
            },
            "LUSH": { 
                url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x8a0Aec7d4F9949A346CF5Fe2cF824CC54db4AD20", 
                platform: "Ethereum" 
            },
            "ROOBEE": { 
                url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xa31B1767e09f842ECFd4bc471Fe44F830E3891Aa", 
                platform: "Ethereum" 
            },
            "GNY": { 
                url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x8c5B7F27a50eF6e9e8bCAeBDAE23ae396FeC290A", 
                platform: "Ethereum" 
            },
            "MOOV": { 
                url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xB7b0b3635AA09dB1E401aC8EabB2E99D07E33eD0", 
                platform: "Ethereum" 
            },
            "GOC": { 
                url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x69Af81e73A73B40adF4F3d4223Cd9b1ECE623074", 
                platform: "Ethereum" 
            },
            "DERI": { 
                url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xa487A154573A32D3143e72E9ED36a2d8a50c1347", 
                platform: "Ethereum" 
            },
            "WAM": { 
                url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x4d10A9e7c6e6a4efEA09809b262aA38116F04f6D", 
                platform: "Ethereum" 
            },
            // Continuing with the rest...
            
            "LBC": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x29d75277ac7F0335b2165D0895E87256bEd41c4b", 
                    platform: "Ethereum" 
                },
                "UNO": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x474021845C4643113458ea4414bdb7fB74A01A77", 
                    platform: "Ethereum" 
                },
                "NEURA": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x0000000000A39bb272e79075ade125fd351887Ac", 
                    platform: "Ethereum" 
                },
                "ABOND": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xD33526068D116cE69F19A9ee46F0bd304F21A51f", 
                    platform: "Ethereum" 
                },
                "AXIS": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x865377367054516e17014CcdED1e7d814EDC9ce4", 
                    platform: "Ethereum" 
                },
                "BIRD": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x70401dFd142A16dC7031c56E862Fc88Cb9537Ce0", 
                    platform: "Ethereum" 
                },
                "XMON": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x9c9272401e4D98E2AAd34dFb715Ad78b695D69B5", 
                    platform: "Ethereum" 
                },
                "IDNA": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x0De05F6447ab4D22c8827449E7686A5E8f1F3b7F", 
                    platform: "Ethereum" 
                },
                "AIMX": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xC36e97E8a1020B062c9B23DE844d410C06d0A0D1", 
                    platform: "Ethereum" 
                },
                "AIRI": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x26e43759551333e57F073bb0772F50329A957b30", 
                    platform: "Ethereum" 
                },
                "TRV": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x23B5d0E824B275dF02a9f64C4bB29A5d7F768c2b", 
                    platform: "Ethereum" 
                },
                "ALLIN": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xF45d97FcB03a623dF9c19d5D55bCe21E21b5b3ac", 
                    platform: "Ethereum" 
                },
                "MEFA": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x0eFd30D4d5A5AbEcEF3cA7476Cde53FbB66f53e4", 
                    platform: "Ethereum" 
                },
                "CIRUS": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xA01199C61841Fce3b3daFB83FeFC1899715c8756", 
                    platform: "Ethereum" 
                },
                "MMAI": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xB6A9d6995e74a5E5542B9a658452dF89a7b6aF3b", 
                    platform: "Ethereum" 
                },
                "CATHEON": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x4fDde02CFb1DaA083f4f54Cfe6eaBf540B67a4d1", 
                    platform: "Ethereum" 
                },
                "AIBB": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x1a8b97b78ceF1675bD931fA4f8Ad7eE301c84E9d", 
                    platform: "Ethereum" 
                },
                "TRAVA": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x4e83362442B8d1beC281594cEA3050c8EB01311C", 
                    platform: "Ethereum" 
                },
                "OCE": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x2b1e96E1F0A4376A33FFb64C8D6672A34BCEEB21", 
                    platform: "Ethereum" 
                },
                "ARCONA": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x4e2f4a507B69FD15BB4a3C059fF9D670e13A642E", 
                    platform: "Ethereum" 
                },
                "NEI": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x8bB0EeF06fB3E4a328dD6Bd84b7F69C7eD1B6707", 
                    platform: "Ethereum" 
                },
                "OJA": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xC9d4Eb34bE2091eF66f8F75E32C9BEd24f20C2E7", 
                    platform: "Ethereum" 
                },
                "RAZE": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x52C7bE1bF7CC1eD363EC2cC79AcbC9B5a6cC9470", 
                    platform: "Ethereum" 
                },
                "TRL": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x95aD61b0a150d79219dCF64E1E6Cc01f0B64C4cE", 
                    platform: "Ethereum" 
                },
                "YF-DAI": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xBAe5fF19C716aBdA436b3943e6b5F7a678dDe3B8", 
                    platform: "Ethereum" 
                },
                "CAIR": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xABe580E7ee158dA464b51ee1a83Ac0289622e6be", 
                    platform: "Ethereum" 
                },
                "UPI": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xD8912C10681D8B21Fd3742244f44658dBA12264E", 
                    platform: "Ethereum" 
                },
                "UBEX": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x6701e4DA60aA23e4aaAEb04Ad3436D25cBDd4CB1", 
                    platform: "Ethereum" 
                },
                "CNTM": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x2B7dF4cE2D0fFfaF07a20cc5E76767E1DAFd7E61", 
                    platform: "Ethereum" 
                },
                "ASTO": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x923D96C1d3eE4e1B4e589D2B4a162F7D4a3D17c4", 
                    platform: "Ethereum" 
                },
                "QUBIC": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xBf2179859cDdb3d8770b70529A45b1b0D92Ed0AE", 
                    platform: "Ethereum" 
                },
                "GPT": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x68b3F0ab274e73e2750DF4f0C0F3eC2485576544", 
                    platform: "Ethereum" 
                },
                "LAI": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x4F84C0eA7E1323B6Cb805a9B3F013b6c12bC1E8C", 
                    platform: "Ethereum" 
                },
                "OLAS": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x4F84C0eA7E1323B6Cb805a9B3F013b6c12bC1E8C", 
                    platform: "Ethereum" 
                },
                "DKS": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xB65B22cF1E4d3bE393D69511AABd42d215adA9Ab", 
                    platform: "Ethereum" 
                },
                "WORK": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x79C75E2e8720B39e258F41c37cC4f309E0b0fF80", 
                    platform: "Ethereum" 
                },
                "THR": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x6D2F5aAF09A3BA7c7f1D7E84FD4AfA9bd20e0a3c", 
                    platform: "Ethereum" 
                },
                "XETA": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x65Ef703f5594D2573eb71Aaf55BC0CB548492df4", 
                    platform: "Ethereum" 
                },
                "AIUS": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x0f52b95cC73e5D8A1bB7Ff8c5Aab92e58f1344E1", 
                    platform: "Ethereum" 
                },
                "CORGIAI": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x0c7D5ae016f806603CB1782bEa29AC69471CAb9c", 
                    platform: "Ethereum" 
                },
                "HEART": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x84858c3124387b04d190e8BeC4B02596867842DB", 
                    platform: "Ethereum" 
                },
                "GROK": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x9d66C0D10B5D9Be73A1fA8b11E307101A1f33Ca6", 
                    platform: "Ethereum" 
                },
                "VIA": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x0f52b95cC73e5D8A1bB7Ff8c5Aab92e58f1344E1", 
                    platform: "Ethereum" 
                },
                "GROW": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xC55EADa967B78a35236A13e090a1124966F1564b", 
                    platform: "Ethereum" 
                },
                "AIEPK": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x1De0872fB3a1CBACf93bE9bE7a27D5A5b29F3AE3", 
                    platform: "Ethereum" 
                },
                "RING": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x446c9033e7516D820cc9a2C2a2fCBE557E46c135", 
                    platform: "Ethereum" 
                },
                "FAKEAI": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xDe30dCc0A803cC37959d4012d4FCDc8bD2a8A91E", 
                    platform: "Ethereum" 
                },
                "BCAT": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x186C5BE7e1016fD0dbe213b4dF0278B47C26dC9a", 
                    platform: "Ethereum" 
                },
                "Y8U": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x261B45D85cCFeAbb11F023A634d3dC8e900c9aB4", 
                    platform: "Ethereum" 
                },
                "METO": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x23001F892c0C82B79303d8cA2e4b5e89f14c9f39", 
                    platform: "Ethereum" 
                },
                "AIN": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xa73c167E5Bf2bDcD32f3f3B32b706Fd9F4E9c8ED", 
                    platform: "Ethereum" 
                },
                "VRD": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x5b322514FF727253292637D9054301600b8a645D", 
                    platform: "Ethereum" 
                },
                "HMT": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x0C6e36378A4eC3Bd1F793efCb485f6eED0cfd635", 
                    platform: "Ethereum" 
                },
                "NEURAL": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x23001F892c0C82B79303d8cA2e4b5e89f14c9f39", 
                    platform: "Ethereum" 
                },
                "MND": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x92eF4FFBFE0C108Ff892043a09d3Ff699B7BfaF1", 
                    platform: "Ethereum" 
                },
                "enqAI": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x9d78B617151Aba718BDFAD1E96e1DaBCe7F8418C", 
                    platform: "Ethereum" 
                },
                "AVTM": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x78A3bCaeF540A797700D98e3A54f82AB60BAb4BE", 
                    platform: "Ethereum" 
                },
                "TRACE": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x2f78cCAd9754E30bF9F4778d8E04dC13B4B0156E", 
                    platform: "Ethereum" 
                },
                "TTM": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x2F8cFe05bBB2B6Bf2B215D3B4FfD8d10D01eB3a3", 
                    platform: "Ethereum" 
                },
                "DDD": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x25a3f197d98C231746c44db6eEbCfE755DE7c62A", 
                    platform: "Ethereum" 
                },
                "LAVITA": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x856a7bE9e74fB4eC4adDAc34f720693db5fDbc35", 
                    platform: "Ethereum" 
                },
                "ZCN": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x8eFFd494eB698cc399AF6231fCcd39E08fd20B15", 
                    platform: "Ethereum" 
                },
                "WNK": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x69A95185ee2a045CDC4bCd1b1Df10710395e4e23", 
                    platform: "Ethereum" 
                },
                "ROKO": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x06c80eB3D9f41AC2D12fA799bFc142D5A2516f7e", 
                    platform: "Ethereum" 
                },
                "VRX": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x653430560bE843C4a3d143d0110e896c2Ab8AC0D", 
                    platform: "Ethereum" 
                },
                "BTO": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x36905Fc93280f52362A1CBAB151f25Dc46742Fb5", 
                    platform: "Ethereum" 
                },
                "BAI": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x7d5121505149065b562C789A0145e5A666b41856", 
                    platform: "Ethereum" 
                },
                "ASCN": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x0C41F6a282E9A6CfaABfC9d637BC24FBa35615f1", 
                    platform: "Ethereum" 
                },
                "PAI": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xEcECb8F46A9a8e843C8D670e01FFa3Ff3D4b6f56", 
                    platform: "Ethereum" 
                },
                "GEMAI": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x2f109021aFe75B949429Fe30523Ee7C0D5B27207", 
                    platform: "Ethereum" 
                },
                "PALAI": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xFa8a5e49F9DA19740eAa0D5E38bC2a728A7F2717", 
                    platform: "Ethereum" 
                },
                "MOROS": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x2Bf7B4FCFC6D8d682fc1De61162e67eBb55BEF1D", 
                    platform: "Ethereum" 
                },
                "DHX": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x7b4328c127B85369D9f82ca0503B000D09CF9180", 
                    platform: "Ethereum" 
                },
                "ASTRA": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x44564d0bd94343f72E3C8a0D22308B7Fa71DB0Bb", 
                    platform: "Ethereum" 
                },
                "AIKEK": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xC4cA5654cC3e5e44Ad57DDbC50e1265b03e524AC", 
                    platform: "Ethereum" 
                },
                "TOR": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x6D2F5aAF09A3BA7c7f1D7E84FD4AfA9bd20e0a3c", 
                    platform: "Ethereum" 
                },
                "ORACLE": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x6D2F5aAF09A3BA7c7f1D7E84FD4AfA9bd20e0a3c", 
                    platform: "Ethereum" 
                },
                "2DAI": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x446C9033E7516d820CC9A2C2a2FCbE557e46C135", 
                    platform: "Ethereum" 
                },
                "CLBK": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xB65B22cF1E4d3bE393D69511AABd42d215adA9Ab", 
                    platform: "Ethereum" 
                },
                "CHAT": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x938e0F4Eae9E25D131F746FafFf7c8B0B9C751C6", 
                    platform: "Ethereum" 
                },
                "AIMBOT": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x4eA81E3214A95126b06e737D516cfF9D6AfFa161", 
                    platform: "Ethereum" 
                },
                "NEURONI": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x2aDC861a130D7e6e2180307d6CE3CA4e7B4c72Df", 
                    platform: "Ethereum" 
                },
                "VUZZ": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x4b18F3d564d83A3F63FfF7B84026f5D28B068C09", 
                    platform: "Ethereum" 
                },
                "BUILD": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x534fa4a51aE490E07b65007ebA8C24f0D1400C1d", 
                    platform: "Ethereum" 
                },
                "VRSW": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xB9d99c33e5502B17e800aDb5Ce20c9A6510D6a4C", 
                    platform: "Ethereum" 
                },
                "RAVEN": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x2aD7A6b8fF7cF4a3d6D417A1DBA2E60C1A2a93d8", 
                    platform: "Ethereum" 
                },
                "KWAI": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x3A4EDcf3312f44EF027acfd8c21382a5259936e7", 
                    platform: "Ethereum" 
                },
                "ACRIA": { 
                    url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x17a22E0189D9D63f856d7FF6cE5b349a8AcF2cD9", 
                    platform: "Ethereum" 
                },
              
                 "WEBAI": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x39D23cd28CAd14C75517f08eE2B79Dd3829b51b8", 
                        platform: "Ethereum" 
                    },
                    "AION": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xa0c32CE13dD1C35E051d18d8eFDe098c8278397B", 
                        platform: "Ethereum" 
                    },
                    "KAT": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xC22b30E4cce6bB0113254aD3e3C7fB9E61F69fbB", 
                        platform: "Ethereum" 
                    },
                    "AITK": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x7e29C518208fB7c4921bca35590BE17e4d1113C1", 
                        platform: "Ethereum" 
                    },
                    "SAN": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xC06C036F84821AA5aD35aeb6EeA01793B0fF30A8", 
                        platform: "Ethereum" 
                    },
                    "DLT": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x07E73fF25b0EFD41196724F1D19E33Cc7E51118e", 
                        platform: "Ethereum" 
                    },
                    "CND": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xD417144312DbF50465b1C641d016962017Ef6240", 
                        platform: "Ethereum" 
                    },
                    "DNA": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xBBbbCA6A901c926F240b89EacB641d8Aec7AEafD", 
                        platform: "Ethereum" 
                    },
                    "CPC": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xAe6e4DDbfA98A3A9dBB4b71e3A3dA91D504c94d8", 
                        platform: "Ethereum" 
                    },
                    "NTK": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x69C49F6e74C7A2F2B077C96cD31E4D76B06A0e6D", 
                        platform: "Ethereum" 
                    },
                    "SEELE": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xB5a96eB6bD46BCacF29D4fdf4223F68b68f217f7", 
                        platform: "Ethereum" 
                    },
                    "HAVY": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x4b92f5Ab171dD11C6c3A16De2AeB2f22C3Bf4f84", 
                        platform: "Ethereum" 
                    },
                    "EV": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x1C6833251290574eE1e8aEF6Af6f7c9eDE4bF02b", 
                        platform: "Ethereum" 
                    },
                    "AU": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x50Ff142a71eCFD8e5146D8CE8Ae7eEac5Dd34d66", 
                        platform: "Ethereum" 
                    },
                    "IAI": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xCFb7A47C7CCB4AdE3f030B1a24D5Ab6D1E2a4C4F", 
                        platform: "Ethereum" 
                    },
                    "HERA": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x0f2d719407FdBeFF09D87557AbB7232601FD9F29", 
                        platform: "Ethereum" 
                    },
                    "USHI": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x55cDb750d8664a79e251bE26C4d20F8f5fCeB5C2", 
                        platform: "Ethereum" 
                    },
                    "AIONE": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x10f9ACBEf0A1F1b2e07B37B858707C98Bd86863C", 
                        platform: "Ethereum" 
                    },
                    "SAI": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x8F60F773fFDd6eDd8C7f8Bc6e20fDaFbC3E91E4F", 
                        platform: "Ethereum" 
                    },
                    "LIZA": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xE3CF7455A1d36c30aC52AB7A39DBB5F033d4Dff4", 
                        platform: "Ethereum" 
                    },
                    "GEMINI": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x23D80DA9A2A1c6C81c68f95b924dDD8fA5d6e4d5", 
                        platform: "Ethereum" 
                    },
                    "LNDRY": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x20e239cA7a57D1048e048bB7b916DE4D42585C00", 
                        platform: "Ethereum" 
                    },
                    "BURNIFYAI": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x7c5B4d6b3BeAD714634AC4cDaEf0A68C7463c3D5", 
                        platform: "Ethereum" 
                    },
                    "MDX": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x3A92B51Bf2e44C9D72F9E5c60B8C2485Ab5D19E7", 
                        platform: "Ethereum" 
                    },
                    "INSP": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x2Ff02aA8F7a813b32E8C81B81a0bFE77B2c0bBf1", 
                        platform: "Ethereum" 
                    },
                    "AIMARKET": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x6A9312f2137E90Bf8de40aFF91A3Ba4B7e30066e", 
                        platform: "Ethereum" 
                    },
                    "PING": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x9C676b805D07bC25b4b0C8d2f2d3F7dD56B70E2C", 
                        platform: "Ethereum" 
                    },
                    "TIE": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x14A32fC92745a3D236dDAa234Eaa3F994f55F7aA", 
                        platform: "Ethereum" 
                    },
                    "PRA": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x3c6a6091C47D9DCB61b6DB54F79a5cB06f4C92C6", 
                        platform: "Ethereum" 
                    },
                    "DAT": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x0cf0e8F7A832E227eBda79dCFC7A90714C14790b", 
                        platform: "Ethereum" 
                    },
                    "SPHTX": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x5B4D9ABeB1201Ac572B2e468f0B90F2cB32C7c5d", 
                        platform: "Ethereum" 
                    },
                    "CAT": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x123d6d68E04Fa322E49757A91CdbD1F8b9b2ad0d", 
                        platform: "Ethereum" 
                    },
                    "AIDOC": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x8A9C29B3A70527A1D3Aa1Db39d60a80a63dB568C", 
                        platform: "Ethereum" 
                    },
                    "INSTAR": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xC72b5a9cD3aB1DFe0D4dEb1025E0D23E8013C194", 
                        platform: "Ethereum" 
                    },
                    "DATX": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x9f7dD1bE42306b4a3Caa8E79dD2d9C3AaF34394f", 
                        platform: "Ethereum" 
                    },
                    "LDC": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x95dB1D4442A6F46C7A6CBF7C5aA611EB4b299Ea3", 
                        platform: "Ethereum" 
                    },
                    "ADH": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x2c9484C4B743dCe503F3f9A1B6E7A4Ef27E52aD4", 
                        platform: "Ethereum" 
                    },
                    "MTC": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xDD1Ad9A21Ce722C151A836373baBe42c868cE9a4", 
                        platform: "Ethereum" 
                    },
                    "DAC": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x0d438F3b5175Bebc262bF23753C1E53d03432bDE", 
                        platform: "Ethereum" 
                    },
                    "QBIT": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x9eF5eDE9B2d3Bc4D59ACa4781a0eF2d76980dA42", 
                        platform: "Ethereum" 
                    },
                    "FOAM": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x4946Fcea7C692606e8908002e55A582af44AC121", 
                        platform: "Ethereum" 
                    },
                    "LML": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xB09FE1613fE03E7361319d2a43eDc17422f36B09", 
                        platform: "Ethereum" 
                    },
                    "ELAMA": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x1dECAFC8A9a1B722Aa3b6bDbf7cE0471FFa9A97f", 
                        platform: "Ethereum" 
                    },
                    "JAR": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xA7bEa8F9AcD50e5b38b9181FfE9c47701b88b456", 
                        platform: "Ethereum" 
                    },
                    "CIX100": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xB8B1A1276A2C1e2e178A94a323cD22309b2fA7B8", 
                        platform: "Ethereum" 
                    },
                    "SLV": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x4b7DD6a6241a82F5FA8dd3b1C937A8857a19c198", 
                        platform: "Ethereum" 
                    },
                    "FAB": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xB35a24076e1B4E2980B399E7671f34A4407D1A37", 
                        platform: "Ethereum" 
                    },
                    "MB": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x9A82fd8DFAf24D7aA6406F64AdD7De3A8a51CC3C", 
                        platform: "Ethereum" 
                    },
                    "REL": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xD291E7a03283640FDc51b121aC401383A46cC623", 
                        platform: "Ethereum" 
                    },
                    "ANW": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x1C19F1A04D3D2278Bf1ebd2F3cAc040190BbbE9D", 
                        platform: "Ethereum" 
                    },
                    "MBN": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x4F7a91F6DC45a9E1eA34D5f1F4Bb38e66fDFE43D", 
                        platform: "Ethereum" 
                    },
                    "AITRA": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x7EeF691E8DB759bA6e42d1dd648bD9A559BE5bC0", 
                        platform: "Ethereum" 
                    },
                    "UTU": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x16ba8EFE847EBDFEf99d3999024Bff110dA0d848", 
                        platform: "Ethereum" 
                    },
                    "RIT20": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x08051A4B6343C0f3E844fD79b68F2747C4b3e4c4", 
                        platform: "Ethereum" 
                    },
                    "TESLF": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xC7F93E03ECA139365faF597B3BFF0737B2D5fd9E", 
                        platform: "Ethereum" 
                    },
                    "MAI": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x3F78f94a4b6E53f1Db3567b1c94299D08B6E982F", 
                        platform: "Ethereum" 
                    },
                    "MOVE": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xa8006C4ca56F24d6836727D106349320dB7fEF82", 
                        platform: "Ethereum" 
                    },
                    "QUAD": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x30CFabD642f605Bc5eCd192a92c61E4c88DCEc7C", 
                        platform: "Ethereum" 
                    },
                    "BRAIN": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xA8bAc59fCe195fF491Fe6D80F193D9fD3B64C1c9", 
                        platform: "Ethereum" 
                    },
                    "PEPES": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x1f521456c2E39A3e39229A19a54d92eD287b6d4F", 
                        platform: "Ethereum" 
                    },
                    "rushAI": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xA55870278d6389ec5B524553D03C04f5677c061E", 
                        platform: "Ethereum" 
                    },
                    "MIDAI": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x5090eD5F61b7e423e1c5D7DBB70Beed3c6a17f1B", 
                        platform: "Ethereum" 
                    },
                    "KAE": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x29D72009F478Dd5d5f14A7819Cef3f4A63b24B89", 
                        platform: "Ethereum" 
                    },
                    "CIPHER": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xC2E1ACEbB06FA449aEC58cBb27dE299047Ed96bc", 
                        platform: "Ethereum" 
                    },
                    "DEFLECT": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xdef1ca1FB7FBcdc777520AA7F396b4e015F497aB", 
                        platform: "Ethereum" 
                    },
                    "DTEC": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x3D6D550253Fb73D866D135d0650eCAb278a2cC97", 
                        platform: "Ethereum" 
                    },
                    "WELLCHAIN": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xD3c5f2190Cf2eC8b6d5fE1e4D8b1E4Ab32C1323A", 
                        platform: "Ethereum" 
                    },
                    "ASI": { 
                        url: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xF3a602dE0B4AFbD100C274bA65a568cA0A1e6b07", 
                        platform: "Ethereum" 
                    }
               
                
    
};